import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "22",
  height: "16",
  fill: "none",
  viewBox: "0 0 22 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#8E8E93",
      "fill-rule": "evenodd",
      d: "m17.494 2.933-2.75-1.6H7.256l-2.75 1.6H1.375v11.734h19.25V2.933zM4.125 1.6h-2.75C.615 1.6 0 2.197 0 2.933v11.734C0 15.403.616 16 1.375 16h19.25c.76 0 1.375-.597 1.375-1.333V2.933c0-.736-.616-1.333-1.375-1.333h-2.75L15.452.19a1.4 1.4 0 0 0-.708-.19H7.256c-.25 0-.494.066-.708.19z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#8E8E93",
      "fill-rule": "evenodd",
      d: "M11 12.001c2.278 0 4.125-1.79 4.125-4s-1.847-4-4.125-4-4.125 1.791-4.125 4 1.847 4 4.125 4m0 1.334c3.038 0 5.5-2.388 5.5-5.334S14.038 2.668 11 2.668 5.5 5.056 5.5 8.001s2.462 5.334 5.5 5.334",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }