import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "13",
  height: "12",
  fill: "none",
  viewBox: "0 0 13 12"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      stroke: "#4F4F4F",
      opacity: ".4"
    }, [
      _createElementVNode("circle", {
        cx: "6",
        cy: "6",
        r: "5.5"
      }),
      _createElementVNode("path", { d: "M6 2v4h3" })
    ], -1)
  ])))
}
export default { render: render }