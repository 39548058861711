import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "21",
  height: "20",
  fill: "none",
  viewBox: "0 0 21 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      stroke: "#333",
      opacity: ".8"
    }, [
      _createElementVNode("rect", {
        width: "20",
        height: "19",
        x: ".5",
        y: ".5",
        rx: "1.5"
      }),
      _createElementVNode("path", { d: "M17 5.884a1.843 1.843 0 0 1-1.833 1.853 1.843 1.843 0 0 1-1.833-1.853c0-1.027.824-1.853 1.833-1.853S17 4.857 17 5.884ZM.5 18v-6.002L5.912 7.45a1.5 1.5 0 0 1 2.03.092l4.993 5.035a1.5 1.5 0 0 0 2.13 0l1.493-1.505a1.5 1.5 0 0 1 2.03-.093l1.912 1.607V18a1.5 1.5 0 0 1-1.5 1.5H2A1.5 1.5 0 0 1 .5 18Z" })
    ], -1)
  ])))
}
export default { render: render }