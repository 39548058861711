import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15",
  height: "14",
  fill: "none",
  viewBox: "0 0 15 14"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#B9B9B9",
      d: "M13.53 8.135a1.5 1.5 0 0 1-1.5 1.5H7.016a.77.77 0 0 0-.567.252l-2.58 2.824v-2.269c0-.402-.309-.807-.781-.807H2a1.5 1.5 0 0 1-1.5-1.5V2A1.5 1.5 0 0 1 2 .5h10.03a1.5 1.5 0 0 1 1.5 1.5z"
    }, null, -1)
  ])))
}
export default { render: render }