import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "21",
  height: "19",
  fill: "none",
  viewBox: "0 0 21 19"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#000",
      d: "M15.5 13.976h-.693l.218.658.7 2.11a.5.5 0 0 1-.715.595l-5.77-3.162-.108-.06-.124-.002-7.031-.108a1.5 1.5 0 0 1-1.477-1.5V2A1.5 1.5 0 0 1 2 .5h17A1.5 1.5 0 0 1 20.5 2v10.476a1.5 1.5 0 0 1-1.5 1.5z",
      opacity: ".5"
    }, null, -1),
    _createElementVNode("path", {
      fill: "gray",
      d: "M8.389 5.866h1.128c.025-.534.341-.879.82-.879.474 0 .79.326.79.775 0 .45-.162.681-.699 1.045-.574.383-.803.808-.749 1.55l.008.274h1.103v-.25c0-.463.154-.695.708-1.059.587-.392.89-.888.89-1.602 0-1.078-.786-1.83-1.976-1.83-1.278 0-2.003.814-2.023 1.976m1.864 5.025c.38 0 .683-.326.683-.742s-.304-.743-.683-.743c-.374 0-.678.327-.678.743s.304.742.678.742"
    }, null, -1)
  ])))
}
export default { render: render }