import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "22",
  height: "24",
  fill: "none",
  viewBox: "0 0 22 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#4F4F4F",
      d: "M19.385 19.826h1.043a.52.52 0 0 0 .42-.835l-1.887-2.504a.52.52 0 0 1-.104-.313v-5.221c0-3.59-2.45-6.694-5.892-7.576a.52.52 0 0 1-.394-.505v-.264c0-.864-.703-1.565-1.571-1.565s-1.571.7-1.571 1.565v.264a.52.52 0 0 1-.394.505c-3.442.882-5.892 3.986-5.892 7.576v5.22a.52.52 0 0 1-.104.314L1.153 18.99a.52.52 0 0 0 .42.835h17.812M8.433 20.87A2.62 2.62 0 0 0 11 22.956a2.62 2.62 0 0 0 2.566-2.086zM11 24a3.66 3.66 0 0 1-3.63-3.13H1.572C.704 20.87 0 20.169 0 19.304c0-.338.11-.668.314-.938L2.095 16v-5.047c0-3.932 2.593-7.35 6.29-8.477A2.614 2.614 0 0 1 11 0a2.614 2.614 0 0 1 2.616 2.476c3.696 1.128 6.289 4.545 6.289 8.477V16l1.78 2.366a1.56 1.56 0 0 1-.314 2.19 1.58 1.58 0 0 1-.943.314H14.63A3.66 3.66 0 0 1 11 24"
    }, null, -1)
  ])))
}
export default { render: render }