import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "52",
  height: "39",
  fill: "none",
  viewBox: "0 0 52 39"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      stroke: "#000",
      opacity: ".3"
    }, [
      _createElementVNode("path", { d: "M13 5.5h.17l.135-.104 5.96-4.585A1.5 1.5 0 0 1 20.18.5h11.64c.33 0 .652.11.914.311l5.961 4.585.135.104H46a5.5 5.5 0 0 1 5.5 5.5v22a5.5 5.5 0 0 1-5.5 5.5H6A5.5 5.5 0 0 1 .5 33V11A5.5 5.5 0 0 1 6 5.5z" }),
      _createElementVNode("circle", {
        cx: "26",
        cy: "21",
        r: "9.5"
      }),
      _createElementVNode("circle", {
        cx: "38",
        cy: "13",
        r: "1.5"
      })
    ], -1)
  ])))
}
export default { render: render }