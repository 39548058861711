import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "23",
  height: "16",
  fill: "none",
  viewBox: "0 0 23 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "#333",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      opacity: ".5"
    }, [
      _createElementVNode("path", { d: "M0 2.12C0 .948.92 0 2.054 0h10.678c1.134 0 2.054.949 2.054 2.12v12.29H7.393v-.848h6.571V2.12c0-.702-.552-1.271-1.232-1.271H2.054C1.374.848.82 1.417.82 2.119v10.172c0 .702.552 1.271 1.233 1.271h1.848v.848H2.054C.919 14.41 0 13.461 0 12.291z" }),
      _createElementVNode("path", { d: "M16.018 5.933c-.68 0-1.232.57-1.232 1.271v6.358h1.643v.847h-2.465V7.204c0-1.17.92-2.119 2.054-2.119h1.449c.778 0 1.489.454 1.837 1.172l.67 1.383c.119.246.312.445.55.568l1.34.692A2.13 2.13 0 0 1 23 10.795v1.495c0 1.17-.92 2.12-2.054 2.12h-.41v-.848h.41c.68 0 1.233-.57 1.233-1.272v-1.495c0-.482-.264-.922-.681-1.137l-1.34-.692a2.1 2.1 0 0 1-.919-.947l-.67-1.383a1.23 1.23 0 0 0-1.102-.703zM5.647 15.152c.907 0 1.643-.759 1.643-1.695s-.736-1.695-1.643-1.695-1.643.759-1.643 1.695.736 1.695 1.643 1.695m0 .848c1.36 0 2.464-1.139 2.464-2.543s-1.103-2.543-2.464-2.543-2.464 1.139-2.464 2.543S4.286 16 5.647 16" }),
      _createElementVNode("path", { d: "M18.482 15.152c.908 0 1.643-.759 1.643-1.695s-.735-1.695-1.643-1.695c-.907 0-1.642.759-1.642 1.695s.735 1.695 1.642 1.695m0 .848c1.361 0 2.465-1.139 2.465-2.543s-1.104-2.543-2.465-2.543-2.464 1.139-2.464 2.543S17.121 16 18.482 16" })
    ], -1)
  ])))
}
export default { render: render }