import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "17",
  height: "17",
  fill: "none",
  viewBox: "0 0 17 17"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "red",
      d: "M7.98 8.957h1.3q.854 0 1.307-.435.43-.419.43-1.177 0-.751-.43-1.164-.447-.436-1.306-.436H7.98zm0 2.743V13H6.962v-1.3h-1.13v-.77h1.13V9.84h-1.13v-.883h1.13V4.84h2.584q1.131 0 1.833.719.684.695.684 1.786t-.69 1.787q-.702.707-1.838.707H7.98v1.092h2.347v.769z"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "8.5",
      cy: "8.5",
      r: "8",
      stroke: "red"
    }, null, -1)
  ])))
}
export default { render: render }