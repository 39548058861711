import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "17",
  height: "17",
  fill: "none",
  viewBox: "0 0 17 17"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "red",
      d: "M3.276 11.967h.5v4.079l5.768-3.99.128-.09H16a.5.5 0 0 0 .5-.5V1a.5.5 0 0 0-.5-.5H1a.5.5 0 0 0-.5.5v10.467a.5.5 0 0 0 .5.5z"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "red",
      "stroke-linecap": "round",
      d: "M3 4h11M3 8h11"
    }, null, -1)
  ])))
}
export default { render: render }