import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "100",
  height: "81",
  fill: "none",
  viewBox: "0 0 100 81"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#B3B3B3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "m86.474 46.289 4.97-4.593C96.281 37.226 99 31.162 99 24.84s-2.718-12.386-7.557-16.856C86.607 3.512 80.047 1 73.206 1S59.804 3.512 54.968 7.984l-4.97 4.593-4.969-4.593c-10.072-9.309-26.403-9.309-36.475 0-10.072 9.31-10.072 24.402 0 33.712l4.97 4.593L49.999 80l18.237-16.856 9.119-8.427 2.28-2.108"
    }, null, -1)
  ])))
}
export default { render: render }