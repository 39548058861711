import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18",
  height: "18",
  fill: "none",
  viewBox: "0 0 18 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#333",
      d: "M16.138 13.73a10.16 10.16 0 0 1-2.078-6.118V7.11a4.62 4.62 0 0 0-.948-2.792 4.87 4.87 0 0 0-2.468-1.707V1.4c0-.371-.152-.727-.423-.99A1.47 1.47 0 0 0 9.2 0c-.383 0-.75.148-1.021.41-.271.263-.423.619-.423.99v1.212c-.99.3-1.854.898-2.468 1.707a4.62 4.62 0 0 0-.948 2.792v.5a10.16 10.16 0 0 1-2.078 6.12 1.264 1.264 0 0 0-.118 1.356c.111.214.282.394.493.52s.454.193.702.193h3.395c.052.6.334 1.159.79 1.566A2.52 2.52 0 0 0 9.2 18c.62 0 1.219-.226 1.676-.634a2.37 2.37 0 0 0 .79-1.566h3.395c.248 0 .49-.067.702-.193.21-.126.382-.306.493-.52a1.26 1.26 0 0 0-.118-1.356M8.581 1.4a.6.6 0 0 1 .181-.424.63.63 0 0 1 .876 0 .6.6 0 0 1 .181.424v1.04a4.9 4.9 0 0 0-1.238 0zM9.2 17.2c-.402 0-.79-.143-1.09-.4a1.6 1.6 0 0 1-.546-1h3.272c-.05.386-.244.741-.545 1-.302.257-.69.4-1.091.4m6.32-2.474a.5.5 0 0 1-.19.201.5.5 0 0 1-.27.073H3.34a.53.53 0 0 1-.27-.074.5.5 0 0 1-.19-.2.49.49 0 0 1 .046-.52 10.95 10.95 0 0 0 2.24-6.594V7.11a3.85 3.85 0 0 1 1.181-2.766A4.1 4.1 0 0 1 9.2 3.2a4.1 4.1 0 0 1 2.853 1.145 3.85 3.85 0 0 1 1.181 2.766v.5a10.95 10.95 0 0 0 2.24 6.595.48.48 0 0 1 .046.52"
    }, null, -1)
  ])))
}
export default { render: render }