import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "21",
  fill: "none",
  viewBox: "0 0 20 21"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M10.591.59v9.648A3 3 0 0 1 13 13.176a3.004 3.004 0 0 1-2.409 2.94v4.294c0 .328-.262.59-.591.59a.59.59 0 0 1-.591-.59v-4.295A3.004 3.004 0 0 1 7 13.176a3.004 3.004 0 0 1 2.409-2.938V.59c0-.328.263-.59.591-.59.329 0 .591.262.591.59M8.183 13.176a1.817 1.817 0 0 0 3.635 0 1.817 1.817 0 0 0-3.636 0M3.591.59v4.697A3 3 0 0 1 6 8.222a3 3 0 0 1-2.409 2.938v9.25c0 .328-.263.59-.591.59a.59.59 0 0 1-.591-.59v-9.245A3 3 0 0 1 0 8.226a3.004 3.004 0 0 1 2.409-2.939V.59C2.409.262 2.672 0 3 0s.591.262.591.59M1.183 8.226a1.817 1.817 0 0 0 3.635 0 1.817 1.817 0 0 0-3.635 0M17.591.59v4.696A2.994 2.994 0 0 1 20 8.22a3 3 0 0 1-2.409 2.938v9.252c0 .328-.263.59-.591.59a.59.59 0 0 1-.591-.59v-9.248A3 3 0 0 1 14 8.224a3 3 0 0 1 2.409-2.938V.59c0-.328.263-.59.591-.59s.591.262.591.59m-2.409 7.634a1.817 1.817 0 0 0 3.635 0 1.817 1.817 0 0 0-3.635 0",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }