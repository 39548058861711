import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#4F4F4F",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M19.21 1.09H3.939A3.273 3.273 0 0 0 .665 4.365v15.272a3.273 3.273 0 0 0 3.273 3.273h15.273a3.273 3.273 0 0 0 3.273-3.273V4.364A3.273 3.273 0 0 0 19.21 1.09M11.575 6.545v10.91M17.03 12H6.12"
    }, null, -1)
  ])))
}
export default { render: render }