import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "110",
  height: "86",
  fill: "none",
  viewBox: "0 0 110 86"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#E0E0E0",
      d: "M1.27 47.23C.422 46.384 0 45.115 0 44.268c0-.846.423-2.115 1.27-2.961l5.922-5.923c1.693-1.693 4.231-1.693 5.923 0l.424.423 23.269 24.961c.846.846 2.115.846 2.961 0L96.462 1.961h.423c1.692-1.693 4.23-1.693 5.923 0l5.923 5.923c1.692 1.692 1.692 4.23 0 5.923l-67.692 70.23c-.847.847-1.693 1.27-2.962 1.27s-2.115-.423-2.962-1.27l-33-35.538z"
    }, null, -1)
  ])))
}
export default { render: render }