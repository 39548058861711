import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "22",
  height: "31",
  fill: "none",
  viewBox: "0 0 22 31"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M4.277 18.6v10.466c0 .514.544.82.95.536l4.756-3.339a1.76 1.76 0 0 1 2.034 0l4.755 3.339c.406.285.95-.022.95-.536V18.922h1.222v10.144c0 1.542-1.632 2.462-2.85 1.607l-4.755-3.339a.585.585 0 0 0-.678 0l-4.756 3.339c-1.218.855-2.85-.065-2.85-1.607V18.6z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M11 21.08c5.4 0 9.778-4.441 9.778-9.92S16.4 1.24 11 1.24s-9.778 4.441-9.778 9.92S5.6 21.08 11 21.08m0 1.24c6.075 0 11-4.997 11-11.16S17.075 0 11 0 0 4.997 0 11.16s4.925 11.16 11 11.16",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M6.544 10.722a.605.605 0 0 1 .864 0l3.025 3.069 5.618-5.7a.605.605 0 0 1 .864 0 .626.626 0 0 1 0 .877l-6.05 6.138a.605.605 0 0 1-.864 0l-3.457-3.507a.626.626 0 0 1 0-.877",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }