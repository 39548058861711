import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 25 25"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#333",
      d: "M1.707 17.707a1 1 0 0 1 0-1.414l9.177-9.178a1 1 0 0 1 .728-.292l5.545.112a1 1 0 0 1 .98.98l.111 5.544a1 1 0 0 1-.292.728l-9.178 9.177a1 1 0 0 1-1.414 0z"
    }, null, -1),
    _createElementVNode("ellipse", {
      cx: "14.576",
      cy: "10.496",
      stroke: "#333",
      rx: "1.2",
      ry: "1.25",
      transform: "rotate(-45 14.576 10.496)"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#4F4F4F",
      stroke: "#333",
      "stroke-width": ".625",
      d: "m20.357 4.274.442.442-4.76 4.76-.441-.443z"
    }, null, -1)
  ])))
}
export default { render: render }