import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 39 43"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#333\" fill-rule=\"evenodd\" d=\"M24.103 5a.5.5 0 0 1 .5-.5c.656 0 1.332.093 1.858.412.568.344.904.915.904 1.704s-.336 1.36-.904 1.704c-.526.319-1.202.412-1.858.412a.5.5 0 0 1 0-1c.592 0 1.048-.09 1.34-.267.25-.152.422-.388.422-.85 0-.46-.171-.696-.422-.848-.292-.177-.748-.267-1.34-.267a.5.5 0 0 1-.5-.5\" clip-rule=\"evenodd\"></path><path fill=\"#333\" fill-rule=\"evenodd\" d=\"M24.103 10.17V8.23h1v1.94z\" clip-rule=\"evenodd\"></path><path fill=\"#333\" fill-rule=\"evenodd\" d=\"M24.047 9.853a1.5 1.5 0 0 1 1.113 0l5.72 2.286c1.533.613 1.094 2.893-.557 2.893H18.885c-1.652 0-2.09-2.28-.557-2.893zm.742.929a.5.5 0 0 0-.371 0l-5.719 2.286c-.511.204-.365.964.186.964h11.438c.55 0 .696-.76.185-.964zM39 7.5H12v-1h27z\" clip-rule=\"evenodd\"></path><path fill=\"#333\" fill-rule=\"evenodd\" d=\"M37 1H2a1 1 0 0 0-1 1v39a1 1 0 0 0 1 1h35a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1M2 0a2 2 0 0 0-2 2v39a2 2 0 0 0 2 2h35a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z\" clip-rule=\"evenodd\"></path><path fill=\"#333\" fill-rule=\"evenodd\" d=\"M11.5 42V1h1v41z\" clip-rule=\"evenodd\"></path>", 5)
  ])))
}
export default { render: render }