import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18",
  height: "15",
  fill: "none",
  viewBox: "0 0 18 15"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "red",
      d: "M.5 3.308a.5.5 0 0 1 .5-.5h1.953a1.5 1.5 0 0 0 1.074-.453L5.69.65 5.347.318l.342.333A.5.5 0 0 1 6.047.5h5.906a.5.5 0 0 1 .358.151l1.662 1.704a1.5 1.5 0 0 0 1.074.453H17a.5.5 0 0 1 .5.5V14a.5.5 0 0 1-.5.5H1a.5.5 0 0 1-.5-.5z"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "9",
      cy: "8",
      r: "4.5",
      stroke: "red"
    }, null, -1)
  ])))
}
export default { render: render }