import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15",
  height: "22",
  fill: "none",
  viewBox: "0 0 15 22"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      fill: "#333",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      opacity: ".5"
    }, [
      _createElementVNode("path", { d: "M2.915 12.602v7.09c0 .348.371.555.648.362l3.242-2.261c.42-.293.967-.293 1.387 0l3.242 2.261c.277.193.648-.014.648-.363V12.82h.833v6.871c0 1.045-1.112 1.668-1.943 1.089L7.73 18.518a.4.4 0 0 0-.462 0L4.025 20.78c-.83.579-1.943-.044-1.943-1.089v-7.09z" }),
      _createElementVNode("path", { d: "M7.5 14.28c3.682 0 6.667-3.009 6.667-6.72S11.182.84 7.5.84.833 3.849.833 7.56s2.985 6.72 6.667 6.72m0 .84c4.142 0 7.5-3.385 7.5-7.56S11.642 0 7.5 0 0 3.385 0 7.56s3.358 7.56 7.5 7.56" }),
      _createElementVNode("path", { d: "M4.463 7.262a.414.414 0 0 1 .589 0L7.114 9.34l3.83-3.86a.414.414 0 0 1 .59 0 .42.42 0 0 1 0 .594L7.409 10.23a.414.414 0 0 1-.59 0L4.464 7.856a.42.42 0 0 1 0-.594" })
    ], -1)
  ])))
}
export default { render: render }