import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18",
  height: "18",
  fill: "none",
  viewBox: "0 0 18 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#8E8E93",
      "fill-rule": "evenodd",
      d: "M7.583 13.903a6.32 6.32 0 1 0 0-12.639 6.32 6.32 0 0 0 0 12.639m0 1.264A7.583 7.583 0 1 0 7.583 0a7.583 7.583 0 0 0 0 15.167",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#8E8E93",
      "fill-rule": "evenodd",
      d: "M12.191 12.19a.63.63 0 0 1 .894 0l4.468 4.468a.632.632 0 0 1-.894.894l-4.468-4.468a.63.63 0 0 1 0-.894",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }