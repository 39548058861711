import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "13",
  height: "12",
  fill: "none",
  viewBox: "0 0 13 12"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#4F4F4F",
      d: "M6.024 1.082a.5.5 0 0 1 .952 0l.732 2.255a1.5 1.5 0 0 0 1.427 1.036h2.371a.5.5 0 0 1 .294.905L9.882 6.672a1.5 1.5 0 0 0-.545 1.677l.733 2.255a.5.5 0 0 1-.77.56L7.382 9.768a1.5 1.5 0 0 0-1.764 0L3.7 11.163a.5.5 0 0 1-.77-.559l.733-2.255a1.5 1.5 0 0 0-.545-1.677L1.2 5.278a.5.5 0 0 1 .294-.905h2.371a1.5 1.5 0 0 0 1.427-1.036z",
      opacity: ".4"
    }, null, -1)
  ])))
}
export default { render: render }