import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "23",
  height: "28",
  fill: "none",
  viewBox: "0 0 23 28"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M21.79 11.529V4.814a.3.3 0 0 0-.333-.3A11.78 11.78 0 0 1 13.23 2.28l-1.22-.9a.86.86 0 0 0-1.018 0l-1.22.9a11.78 11.78 0 0 1-8.228 2.237.3.3 0 0 0-.332.3v6.712c0 5.007 2.376 9.716 6.401 12.685l3.17 2.339a1.21 1.21 0 0 0 1.436 0l3.17-2.339a15.76 15.76 0 0 0 6.402-12.685M1.67 3.31A1.51 1.51 0 0 0 0 4.815v6.713C0 16.92 2.56 21.992 6.894 25.19l3.17 2.338c.854.63 2.018.63 2.872 0l3.17-2.338A16.98 16.98 0 0 0 23 11.529V4.814c0-.898-.777-1.599-1.67-1.505a10.57 10.57 0 0 1-7.383-2.006l-1.22-.9a2.07 2.07 0 0 0-2.454 0l-1.22.9A10.57 10.57 0 0 1 1.67 3.31",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M14.526 12.83H8.474c-.669 0-1.21.543-1.21 1.213v3.636c0 .67.541 1.212 1.21 1.212h6.052c.669 0 1.21-.543 1.21-1.212v-3.636c0-.67-.541-1.212-1.21-1.212M8.474 11.62a2.423 2.423 0 0 0-2.421 2.424v3.636a2.423 2.423 0 0 0 2.42 2.424h6.053a2.423 2.423 0 0 0 2.422-2.424v-3.636a2.423 2.423 0 0 0-2.422-2.424z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M13.316 11.619H9.684v-1.212c0-.67.542-1.212 1.21-1.212h1.211c.669 0 1.21.542 1.21 1.212zM8.474 12.83v-2.424a2.423 2.423 0 0 1 2.42-2.424h1.211a2.423 2.423 0 0 1 2.421 2.424v2.424z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#333",
      d: "M10.691 15.556a.973.973 0 1 1 1.617 0l-.034.051c-.11.166-.169.36-.169.559v.907a.606.606 0 1 1-1.21 0v-.907c0-.2-.06-.393-.17-.559z"
    }, null, -1)
  ])))
}
export default { render: render }