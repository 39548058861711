import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "26",
  height: "16",
  fill: "none",
  viewBox: "0 0 26 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#000",
      d: "M25.331 7.721q.107.18.15.279-.044.099-.15.279a10 10 0 0 1-.662.944 18.2 18.2 0 0 1-2.574 2.644C19.869 13.741 16.729 15.5 13 15.5c-3.743 0-6.886-1.605-9.103-3.39-1.108-.891-1.975-1.82-2.56-2.598a7 7 0 0 1-.652-1.013c-.137-.268-.177-.424-.184-.483l.025-.07q.042-.113.15-.303c.143-.255.362-.586.657-.968.59-.764 1.46-1.709 2.57-2.628C6.124 2.205 9.265.5 13 .5c3.729 0 6.869 1.76 9.095 3.633a18.2 18.2 0 0 1 2.574 2.644c.296.378.517.702.662.944Z"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "13",
      cy: "8",
      r: "5.5",
      stroke: "#000"
    }, null, -1)
  ])))
}
export default { render: render }