import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 50 37"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M48 1H2a1 1 0 0 0-1 1v33a1 1 0 0 0 1 1h46a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1M2 0h46a2 2 0 0 1 2 2v33a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M49 8.5H1v-1h48zM49 12.5H1v-1h48zM38.667 15.28l-.57.456A12.26 12.26 0 0 1 33 18.152v3.157a11 11 0 0 0 3.158 7.714l2.509 2.55 2.508-2.55a11 11 0 0 0 3.158-7.713v-3.158a12.26 12.26 0 0 1-5.097-2.416zm-6.02 1.924a.804.804 0 0 0-.647.789v3.317a12 12 0 0 0 3.445 8.414l2.509 2.551a1 1 0 0 0 1.426 0l2.508-2.55a12 12 0 0 0 3.445-8.416v-3.316a.804.804 0 0 0-.646-.79 11.26 11.26 0 0 1-4.826-2.248l-.57-.455a1 1 0 0 0-1.249 0l-.57.455a11.26 11.26 0 0 1-4.825 2.249",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "m38.121 24.072-1.767-1.767-.707.707 2.474 2.475 4.597-4.597-.708-.707z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }