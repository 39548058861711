import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "19",
  fill: "none",
  viewBox: "0 0 16 19"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g fill=\"#333\" opacity=\".5\"><path fill-rule=\"evenodd\" d=\"M15.158 7.823V3.268a.207.207 0 0 0-.231-.204 8.35 8.35 0 0 1-5.724-1.517l-.849-.61a.61.61 0 0 0-.708 0l-.849.61a8.35 8.35 0 0 1-5.724 1.517.21.21 0 0 0-.23.204v4.555c0 3.397 1.652 6.592 4.453 8.607L7.5 18.017a.86.86 0 0 0 1 0l2.204-1.587c2.8-2.015 4.454-5.21 4.454-8.607M1.16 2.246C.541 2.182 0 2.658 0 3.268v4.555c0 3.659 1.78 7.1 4.796 9.27l2.205 1.586a1.72 1.72 0 0 0 1.998 0l2.205-1.586C14.22 14.923 16 11.482 16 7.823V3.268c0-.61-.54-1.086-1.162-1.022A7.5 7.5 0 0 1 9.703.885l-.848-.61a1.47 1.47 0 0 0-1.708 0l-.848.61A7.5 7.5 0 0 1 1.16 2.246\" clip-rule=\"evenodd\"></path><path fill-rule=\"evenodd\" d=\"M10.106 8.705h-4.21a.83.83 0 0 0-.843.823v2.467c0 .454.377.823.842.823h4.21a.83.83 0 0 0 .843-.823V9.528a.83.83 0 0 0-.842-.823m-4.21-.822c-.931 0-1.685.736-1.685 1.645v2.467c0 .909.754 1.645 1.684 1.645h4.21c.93 0 1.685-.737 1.685-1.645V9.528c0-.909-.754-1.645-1.684-1.645z\" clip-rule=\"evenodd\"></path><path fill-rule=\"evenodd\" d=\"M9.264 7.885H6.738v-.822c0-.454.377-.823.842-.823h.842c.465 0 .842.369.842.823zm-3.368.823V7.063c0-.909.754-1.645 1.684-1.645h.842c.93 0 1.684.736 1.684 1.645v1.645z\" clip-rule=\"evenodd\"></path><path d=\"M7.439 10.558c-.3-.439.022-1.027.562-1.027s.862.588.563 1.027l-.024.035a.67.67 0 0 0-.118.379v.615a.416.416 0 0 1-.42.412.416.416 0 0 1-.422-.412v-.615a.67.67 0 0 0-.117-.38z\"></path></g>", 1)
  ])))
}
export default { render: render }