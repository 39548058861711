import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12",
  fill: "none",
  viewBox: "0 0 12 12"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#219653",
      d: "M0 0h12v12H0z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#219653",
      "fill-rule": "evenodd",
      d: "M11 1H1v10h10zM0 0v12h12V0z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M6.525 7.194H5.79l-.057-4.592h.852zM5.703 8.59q0-.197.119-.33.123-.136.36-.136t.36.137a.46.46 0 0 1 .123.33.46.46 0 0 1-.123.329q-.123.127-.36.127t-.36-.127a.47.47 0 0 1-.12-.33"
    }, null, -1)
  ])))
}
export default { render: render }