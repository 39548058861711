import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 18 17"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", { "clip-path": "url(#a)" }, [
      _createElementVNode("path", {
        fill: "#333",
        "fill-rule": "evenodd",
        d: "M5.068 1.002a3.88 3.88 0 0 1 2.711 1.215l.863.9a.496.496 0 0 0 .716 0l.862-.9.004-.004.003-.003.002-.002.002-.002A3.88 3.88 0 0 1 13.027 1c1.047 0 2.055.434 2.802 1.213l.004.004a4.28 4.28 0 0 1 1.173 2.96 4.28 4.28 0 0 1-1.173 2.96l-.003.003-.01.01-.003.003L9 15.276l-6.834-7.14c-1.562-1.632-1.562-4.287 0-5.92q.003 0 .004-.003a3.88 3.88 0 0 1 2.898-1.211M16.243 9.15l-6.885 7.193a.496.496 0 0 1-.716 0L1.45 8.83l-.004-.004c-1.928-2.019-1.928-5.28 0-7.299l.004-.004a4.833 4.833 0 0 1 7.046 0L9 2.05l.197-.206.303-.317.004-.004a5 5 0 0 1 .32-.306A4.86 4.86 0 0 1 13.051 0a4.88 4.88 0 0 1 3.499 1.523l.004.004A5.29 5.29 0 0 1 18 5.177a5.3 5.3 0 0 1-1.158 3.32 5 5 0 0 1-.288.329l-.004.004z",
        "clip-rule": "evenodd"
      })
    ], -1),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", { id: "a" }, [
        _createElementVNode("path", {
          fill: "#fff",
          d: "M0 0h18v16.497H0z"
        })
      ])
    ], -1)
  ])))
}
export default { render: render }