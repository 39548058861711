import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "33",
  height: "23",
  fill: "none",
  viewBox: "0 0 33 23"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M0 3.046C0 1.364 1.32 0 2.946 0h15.322c1.627 0 2.946 1.364 2.946 3.046v17.67H10.607v-1.22h9.429V3.047c0-1.01-.792-1.827-1.768-1.827H2.946c-.976 0-1.767.818-1.767 1.827V17.67c0 1.01.791 1.828 1.767 1.828h2.652v1.218H2.946C1.32 20.715 0 19.351 0 17.67z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M22.982 8.53c-.976 0-1.768.818-1.768 1.828v9.139h2.357v1.218h-3.535V10.358c0-1.683 1.319-3.047 2.946-3.047h2.079c1.116 0 2.136.652 2.635 1.684l.962 1.988a1.8 1.8 0 0 0 .79.818l1.923.994C32.37 13.31 33 14.365 33 15.52v2.149c0 1.682-1.32 3.046-2.947 3.046h-.589v-1.218h.59c.976 0 1.767-.819 1.767-1.828v-2.15c0-.692-.378-1.325-.977-1.634l-1.923-.994a3 3 0 0 1-1.317-1.363l-.962-1.988c-.3-.619-.911-1.01-1.581-1.01zM8.102 21.781c1.302 0 2.357-1.09 2.357-2.437s-1.055-2.437-2.357-2.437-2.357 1.091-2.357 2.437 1.055 2.437 2.357 2.437m0 1.219c1.953 0 3.536-1.637 3.536-3.656s-1.583-3.655-3.536-3.655-3.536 1.636-3.536 3.655S6.15 23 8.102 23",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M26.518 21.781c1.301 0 2.357-1.09 2.357-2.437s-1.056-2.437-2.357-2.437-2.358 1.091-2.358 2.437 1.056 2.437 2.358 2.437m0 1.219c1.952 0 3.535-1.637 3.535-3.656s-1.583-3.655-3.535-3.655-3.536 1.636-3.536 3.655S24.565 23 26.518 23",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }