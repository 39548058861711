import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "41",
  height: "13",
  fill: "none",
  viewBox: "0 0 41 13"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#101974",
      d: "m15.664.238-5.26 12.625H6.969L4.39 2.767a1.37 1.37 0 0 0-.763-1.101A13 13 0 0 0 .42.598L.487.231H6.01c.36.003.708.135.98.373.273.238.452.566.505.926l1.35 7.295L12.221.23zm13.461 8.491c0-3.33-4.564-3.528-4.53-4.997 0-.469.432-.931 1.39-1.067a6.15 6.15 0 0 1 3.208.564l.56-2.665A8.4 8.4 0 0 0 26.708 0c-3.206 0-5.455 1.734-5.488 4.16 0 1.83 1.62 2.829 2.842 3.4s1.688 1.033 1.688 1.564c0 .83-1.027 1.23-1.951 1.23a6.6 6.6 0 0 1-3.342-.802l-.594 2.767a9.2 9.2 0 0 0 3.605.68c3.375 0 5.623-1.666 5.657-4.297zm8.466 4.1h3.01L37.99.204h-2.747a1.47 1.47 0 0 0-.839.249c-.248.166-.44.404-.552.682l-4.895 11.687h3.376l.675-1.897h4.165zm-3.639-4.46 1.721-4.759.993 4.76zM20.295.211l-2.7 12.625h-3.241L17.054.21z"
    }, null, -1)
  ])))
}
export default { render: render }