import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "17",
  height: "17",
  fill: "none",
  viewBox: "0 0 17 17"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "8.5",
      cy: "8.5",
      r: "8",
      stroke: "red"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "4.5",
      cy: "8.5",
      r: ".5",
      stroke: "red"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "8.5",
      cy: "8.5",
      r: ".5",
      stroke: "red"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "12.5",
      cy: "8.5",
      r: ".5",
      stroke: "red"
    }, null, -1)
  ])))
}
export default { render: render }