import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "13",
  height: "13",
  fill: "none",
  viewBox: "0 0 13 13"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#BDBDBD",
      d: "M6.5 0A6.507 6.507 0 0 0 0 6.5C0 10.084 2.916 13 6.5 13S13 10.084 13 6.5 10.084 0 6.5 0m0 11.818A5.324 5.324 0 0 1 1.182 6.5 5.324 5.324 0 0 1 6.5 1.182 5.324 5.324 0 0 1 11.818 6.5 5.324 5.324 0 0 1 6.5 11.818"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#BDBDBD",
      d: "M6.5 2.757a.789.789 0 0 0 0 1.576.789.789 0 0 0 0-1.576M6.5 5.515a.59.59 0 0 0-.59.591v3.546a.591.591 0 0 0 1.181 0V6.106a.59.59 0 0 0-.59-.59"
    }, null, -1)
  ])))
}
export default { render: render }