import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "14",
  height: "15",
  fill: "none",
  viewBox: "0 0 14 15"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#828282",
      d: "M10.403 5.515c0 2.775-2.222 5.015-4.951 5.015C2.722 10.53.5 8.29.5 5.515S2.722.5 5.452.5s4.951 2.24 4.951 5.015ZM8.387 9.333 13 14"
    }, null, -1)
  ])))
}
export default { render: render }