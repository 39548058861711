import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "10",
  height: "15",
  fill: "none",
  viewBox: "0 0 10 15"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      stroke: "#4F4F4F",
      opacity: ".4"
    }, [
      _createElementVNode("circle", {
        cx: "5",
        cy: "5",
        r: "4.5"
      }),
      _createElementVNode("circle", {
        cx: "5",
        cy: "5",
        r: "2.5"
      }),
      _createElementVNode("path", { d: "M7.5 8.892v5.174l-2.223-1.482L5 12.399l-.277.185L2.5 14.066V8.892l.125.06C3.2 9.224 4.024 9.5 5 9.5a5.6 5.6 0 0 0 2.5-.608Z" })
    ], -1)
  ])))
}
export default { render: render }