import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 25 25"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#010101",
      d: "m12.5.185 2.192 2.71 3.151-1.49.8 3.393 3.485.024-.752 3.403 3.13 1.535-2.154 2.74 2.154 2.74-3.13 1.535.752 3.403-3.485.024-.8 3.393-3.15-1.49-2.193 2.71-2.192-2.71-3.151 1.49-.8-3.392-3.485-.025.752-3.403-3.13-1.535 2.154-2.74L.494 9.76l3.13-1.535-.752-3.403 3.486-.024.799-3.393 3.15 1.49z"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#fff",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m7.765 12.297 3.732 3.519 6.53-6.158"
    }, null, -1)
  ])))
}
export default { render: render }