import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "22",
  height: "24",
  fill: "none",
  viewBox: "0 0 22 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#4F4F4F",
      d: "M11 11.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11m0-1a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9m9.5 9.5a6.5 6.5 0 0 0-6.5-6.5H8A6.5 6.5 0 0 0 1.5 20v2.5h19zM8 12.5h6a7.5 7.5 0 0 1 7.5 7.5v3a.5.5 0 0 1-.5.5H1a.5.5 0 0 1-.5-.5v-3A7.5 7.5 0 0 1 8 12.5"
    }, null, -1)
  ])))
}
export default { render: render }