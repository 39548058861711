import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "18",
  fill: "#fff",
  viewBox: "0 0 48 48"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M5.83 23.616c12.568-5.529 28.832-12.27 31.077-13.203 5.889-2.442 7.696-1.974 6.795 3.434-.647 3.887-2.514 16.756-4.002 24.766-.883 4.75-2.864 5.313-5.979 3.258-1.498-.989-9.059-5.989-10.7-7.163-1.498-1.07-3.564-2.357-.973-4.892.922-.903 6.966-6.674 11.675-11.166.617-.59-.158-1.559-.87-1.086-6.347 4.209-15.147 10.051-16.267 10.812-1.692 1.149-3.317 1.676-6.234.838-2.204-.633-4.357-1.388-5.195-1.676-3.227-1.108-2.461-2.543.673-3.922" }, null, -1)
  ])))
}
export default { render: render }