import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#333",
      d: "M0 3.662c0 .511.085 1.581.655 3.006.698 1.741 1.89 3.46 3.543 5.11 3.296 3.29 5.747 4.497 8.46 4.17 1.361-.153 2.042-.736 2.855-1.542.314-.314.487-.727.487-1.164 0-.42-.161-.812-.453-1.103l-2.36-2.355c-.62-.615-1.638-.601-2.272.031l-.674.674a.57.57 0 0 1-.692.088c-.904-.53-1.617-1.01-2.378-1.768-.67-.669-1.185-1.356-1.777-2.371a.566.566 0 0 1 .089-.687l.678-.678c.313-.312.486-.725.486-1.163 0-.419-.16-.81-.452-1.103L3.835.452c-.619-.615-1.638-.6-2.273.031l-.004.003C.73 1.331.175 1.986.015 3.335l-.001.004C.01 3.38 0 3.49 0 3.662m15.239 9.58a.88.88 0 0 1-.264.626c-.845.839-1.367 1.209-2.405 1.326-2.455.296-4.724-.85-7.833-3.952C1.047 7.562.76 4.51.76 3.662c0-.14.008-.22.01-.24.128-1.073.523-1.58 1.33-2.403.338-.335.875-.349 1.196-.03l2.36 2.355c.147.148.229.35.229.566a.88.88 0 0 1-.263.626l-.678.678a1.325 1.325 0 0 0-.209 1.605c.437.75 1.004 1.636 1.897 2.527.819.816 1.576 1.327 2.531 1.886a1.33 1.33 0 0 0 1.616-.206l.674-.674c.337-.336.873-.35 1.195-.03l2.36 2.354c.148.148.23.349.23.566"
    }, null, -1)
  ])))
}
export default { render: render }