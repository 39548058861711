import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 40 49"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#fff\" stroke=\"#333\" d=\"M10.946 31.988h18.108a9.5 9.5 0 0 1 9.5 9.5v6.014H1.446v-6.014a9.5 9.5 0 0 1 9.5-9.5Z\"></path><path fill=\"#fff\" stroke=\"#333\" d=\"M30.932 18.147c0 7.913-5.002 14.108-10.932 14.108S9.067 26.06 9.067 18.147 14.07 4.04 20 4.04c5.93 0 10.932 6.195 10.932 14.108Z\"></path><path stroke=\"#333\" d=\"M31.432 14.973C31.432 7.256 26.314 1 20 1S8.567 7.256 8.567 14.973\"></path><path fill=\"#fff\" stroke=\"#333\" d=\"M5.257 13.434a.5.5 0 0 1 .5-.5h3.58v7.891h-3.58a.5.5 0 0 1-.5-.5zM34.743 20.324a.5.5 0 0 1-.5.5h-3.58v-7.892h3.58a.5.5 0 0 1 .5.5z\"></path><path stroke=\"#333\" stroke-linecap=\"round\" d=\"M9.838 21.324c2.032.847 6.402 2.54 7.621 2.54\"></path>", 5)
  ])))
}
export default { render: render }