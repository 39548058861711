import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "50",
  height: "14",
  fill: "none",
  viewBox: "0 0 50 14"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#4DB45E\" fill-rule=\"evenodd\" d=\"M13.744 0v.006c-.006 0-1.931-.006-2.444 1.835-.471 1.687-1.798 6.345-1.834 6.474h-.367s-1.357-4.763-1.833-6.48C6.752-.006 4.82 0 4.82 0H.42v14h4.4V5.686h.367L7.754 14h3.057l2.566-8.308h.367V14h4.401V0zM29.819 0s-1.29.116-1.895 1.467l-3.117 6.848h-.367V0h-4.4v14h4.156s1.35-.122 1.956-1.467l3.056-6.847h.366V14h4.401V0zM35.931 6.358V14h4.4V9.538H45.1a4.76 4.76 0 0 0 4.493-3.18z\" clip-rule=\"evenodd\"></path><path fill=\"url(#a)\" d=\"M45.1 0h-9.785a7.03 7.03 0 0 0 6.912 5.747h7.542q.1-.476.098-.978A4.767 4.767 0 0 0 45.1 0\"></path><defs><linearGradient id=\"a\" x1=\"35.314\" x2=\"49.867\" y1=\"2.873\" y2=\"2.873\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#00B4E6\"></stop><stop offset=\".301\" stop-color=\"#00B4E6\"></stop><stop offset=\"1\" stop-color=\"#088CCB\"></stop></linearGradient></defs>", 3)
  ])))
}
export default { render: render }