import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "88",
  height: "101",
  fill: "none",
  viewBox: "0 0 88 101"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#B3B3B3",
      "fill-rule": "evenodd",
      d: "M29 24a4 4 0 1 0 0 8 4 4 0 0 0 0-8m-6 4a6 6 0 1 1 12 0 6 6 0 0 1-12 0M59 24a4 4 0 1 0 0 8 4 4 0 0 0 0-8m-6 4a6 6 0 1 1 12 0 6 6 0 0 1-12 0",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#B3B3B3",
      "fill-rule": "evenodd",
      d: "M0 17a3 3 0 0 1 3-3h82a3 3 0 0 1 3 3v43.04a1 1 0 1 1-2 0V17a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v81a1 1 0 0 0 1 1h82a1 1 0 0 0 1-1V74a1 1 0 1 1 2 0v24a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#B3B3B3",
      "fill-rule": "evenodd",
      d: "M29 29a1 1 0 0 0 1-1V10a8 8 0 0 1 8-8h12a8 8 0 0 1 8 8v18a1 1 0 1 0 2 0V10c0-5.523-4.477-10-10-10H38c-5.523 0-10 4.477-10 10v18a1 1 0 0 0 1 1",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }