import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15",
  height: "14",
  fill: "none",
  viewBox: "0 0 15 14"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#EB5757",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M12.998 2.06A3.33 3.33 0 0 0 10.578 1c-.907 0-1.777.382-2.419 1.06l-.66.699-.658-.698C5.504.647 3.338.647 2.002 2.06s-1.336 3.707 0 5.12l.66.698L7.5 13l4.838-5.12.66-.698A3.73 3.73 0 0 0 14 4.622c0-.961-.36-1.882-1.002-2.561",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }