import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "14",
  fill: "none",
  viewBox: "0 0 20 14"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#F2F2F2",
      d: "M15.846 0H4.154C1.86 0 0 1.84 0 4.109v5.782C0 12.161 1.86 14 4.154 14h11.692C18.14 14 20 12.16 20 9.891V4.109C20 1.839 18.14 0 15.846 0m-2.809 7.281-5.469 2.58a.22.22 0 0 1-.314-.195V4.344a.22.22 0 0 1 .319-.194l5.469 2.742a.216.216 0 0 1-.005.39"
    }, null, -1)
  ])))
}
export default { render: render }