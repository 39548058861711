import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "13",
  height: "13",
  fill: "none",
  viewBox: "0 0 13 13"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#000",
      d: "M11.897.584a1.994 1.994 0 0 0-2.82 0L1.17 8.49a.44.44 0 0 0-.114.195l-1.04 3.753a.443.443 0 0 0 .545.546l3.754-1.04q.112-.032.195-.114l7.907-7.906c.777-.779.777-2.04 0-2.82zM2.135 8.778l6.471-6.47 2.087 2.086-6.47 6.47zm-.417.836 1.668 1.668-2.307.639zM11.79 3.298l-.47.47L9.233 1.68l.47-.47a1.11 1.11 0 0 1 1.567 0l.52.52a1.11 1.11 0 0 1 0 1.567"
    }, null, -1)
  ])))
}
export default { render: render }