import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 50 36"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M0 2.22C0 .995.982 0 2.193 0h28.07c1.211 0 2.193.994 2.193 2.22v27.982h-15.35v-.888h14.473V2.22c0-.736-.59-1.333-1.316-1.333H2.193c-.727 0-1.316.597-1.316 1.333v25.76c0 .736.59 1.333 1.316 1.333h3.728v.888H2.193C.982 30.202 0 29.208 0 27.982z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M40.414 9.771c.562 0 1.062.362 1.244.9l1.886 5.57a2.2 2.2 0 0 0 1.714 1.47l2.765.468c.635.107 1.1.663 1.1 1.314v8.488c0 .736-.59 1.333-1.316 1.333h-2.193v.888h2.193c1.211 0 2.193-.994 2.193-2.22v-8.49a2.21 2.21 0 0 0-1.833-2.19l-2.765-.466a1.32 1.32 0 0 1-1.028-.883l-1.886-5.57a2.195 2.195 0 0 0-2.075-1.5H31.58v21.319h3.849v-.888h-2.972V9.77z",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M32.017 20.431H.438v-.888h31.58zM11.403 34.199c2.665 0 4.825-2.188 4.825-4.886s-2.16-4.886-4.825-4.886-4.824 2.188-4.824 4.886 2.16 4.886 4.824 4.886m0 .888c3.15 0 5.702-2.585 5.702-5.774s-2.553-5.774-5.702-5.774-5.701 2.585-5.701 5.774 2.552 5.774 5.701 5.774M40.351 34.199c2.665 0 4.825-2.188 4.825-4.886s-2.16-4.886-4.825-4.886-4.824 2.188-4.824 4.886 2.16 4.886 4.824 4.886m0 .888c3.15 0 5.702-2.585 5.702-5.774s-2.553-5.774-5.702-5.774-5.702 2.585-5.702 5.774 2.553 5.774 5.702 5.774",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }