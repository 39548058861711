import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 18 18"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#333",
      "fill-rule": "evenodd",
      d: "M12.024 5.684H6.009V7.58h-1V5.684h-2.06l-.925 11.369h13.952L15.05 5.684h-2.027V7.58h-1zm1-1.895v.948h2.305c.365 0 .67.267.698.612l.971 11.937c.031.385-.29.714-.698.714H1.7c-.408 0-.73-.329-.698-.714l.971-11.937c.029-.345.333-.612.698-.612H5.01v-.948C5.009 1.696 6.804 0 9.016 0s4.008 1.696 4.008 3.79m-7.015-.052v1h6.015v-.948l-.001-.052c-.03-1.545-1.364-2.79-3.007-2.79S6.039 2.192 6.01 3.737",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }