import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "34",
  height: "35",
  fill: "none",
  viewBox: "0 0 34 35"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#202020",
      d: "m4.354 13.899 12.443-5.844a1 1 0 0 1 .844-.003l12.372 5.708M19.683 23.069a1 1 0 0 0 1.21.286L31.9 18.186a1 1 0 0 0 .36-1.524L30.083 13.9 17.218 19.94zM4.354 13.899l-2.177 2.763a1 1 0 0 0 .36 1.524l11.006 5.169a1 1 0 0 0 1.21-.286l2.465-3.128zM17.218 30.272l12.29-5.772a1 1 0 0 0 .574-.905v-4.53"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#202020",
      d: "M17.218 19.94v10.348l-12.29-5.772a1 1 0 0 1-.574-.905v-4.539"
    }, null, -1)
  ])))
}
export default { render: render }