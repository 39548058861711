import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "26",
  fill: "none",
  viewBox: "0 0 24 26"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "gray",
      d: "M11.661 11.594a4.1 4.1 0 0 1-2.28-.697A4.13 4.13 0 0 1 7.87 9.04a4.17 4.17 0 0 1 .89-4.508 4.073 4.073 0 0 1 4.472-.897 4.1 4.1 0 0 1 1.841 1.524 4.16 4.16 0 0 1-.512 5.222 4.1 4.1 0 0 1-2.9 1.213m0-7.32c-.625 0-1.237.187-1.757.537s-.926.85-1.165 1.432a3.21 3.21 0 0 0 .688 3.475 3.139 3.139 0 0 0 3.448.688 3.17 3.17 0 0 0 1.418-1.177 3.206 3.206 0 0 0-.399-4.022 3.15 3.15 0 0 0-2.233-.933M20.028 21.429H3.294V17.12c0-1.132.446-2.218 1.24-3.019a4.22 4.22 0 0 1 2.995-1.25h8.264c1.123 0 2.2.45 2.995 1.25s1.24 1.887 1.24 3.02zm-15.793-.95h14.852v-3.358c0-.88-.347-1.725-.965-2.348a3.28 3.28 0 0 0-2.329-.973H7.529a3.28 3.28 0 0 0-2.329.973 3.33 3.33 0 0 0-.965 2.348z"
    }, null, -1)
  ])))
}
export default { render: render }