import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "101",
  height: "64",
  fill: "none",
  viewBox: "0 0 101 64"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#E5E5E5",
      d: "M89.145 63.448a3.49 3.49 0 1 1 0-6.98 3.49 3.49 0 0 1 0 6.98m0-1.269a2.22 2.22 0 1 0 0-4.441 2.22 2.22 0 0 0 0 4.441M85.439 28.803a.635.635 0 0 1-.188-1.085l6.306-5.218a.634.634 0 0 1 1.028.374l1.476 8.05a.634.634 0 0 1-.84.71zm1.475-.814 5.7 2.075-1.082-5.897zM3.172 48.22a3.172 3.172 0 1 1 0-6.344 3.172 3.172 0 0 1 0 6.344m0-1.269a1.903 1.903 0 1 0 0-3.807 1.903 1.903 0 0 0 0 3.807M14.91 20.303a3.49 3.49 0 1 1 0-6.98 3.49 3.49 0 0 1 0 6.98m0-1.269a2.22 2.22 0 1 0 0-4.441 2.22 2.22 0 0 0 0 4.441M68.524 5.076a2.538 2.538 0 1 1 0-5.076 2.538 2.538 0 0 1 0 5.076m0-1.27a1.269 1.269 0 1 0 0-2.537 1.269 1.269 0 0 0 0 2.538M98.345 42.51a2.538 2.538 0 1 1 0-5.076 2.538 2.538 0 0 1 0 5.076m0-1.269a1.269 1.269 0 1 0 0-2.538 1.269 1.269 0 0 0 0 2.538M17.91 33.996a.635.635 0 0 1 1.192.434l-1.775 4.878a.635.635 0 0 1-1.193-.434z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#E5E5E5",
      d: "M20.274 36.944a.635.635 0 0 1-.434 1.193l-4.878-1.776a.635.635 0 0 1 .434-1.192zM14.192 58.843l1.506 2.146 1.506-2.146-1.506-2.145zm2.026-3.614 2.281 3.25a.63.63 0 0 1 0 .729l-2.281 3.25a.634.634 0 0 1-1.039 0l-2.281-3.25a.63.63 0 0 1 0-.73l2.281-3.249a.634.634 0 0 1 1.039 0"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#B3B3B3",
      "fill-rule": "evenodd",
      d: "M49.171 6.715c-2.126 0-3.862 1.744-3.862 3.91v1.066l-.475.123c-7.481 1.946-13.016 8.814-13.016 16.994v11.585l-4.173 5.626c-.21.283-.324.63-.324.985 0 .912.73 1.64 1.615 1.64h40.47a1.6 1.6 0 0 0 .967-.326 1.655 1.655 0 0 0 .324-2.3l-4.172-5.625V37.34a.635.635 0 0 1 1.269 0v2.634l3.922 5.289a2.924 2.924 0 0 1-.577 4.066c-.499.379-1.107.584-1.732.584H56.525c-.319 3.814-3.483 6.82-7.354 6.82s-7.035-3.006-7.353-6.82H28.936c-1.6 0-2.884-1.309-2.884-2.909 0-.627.201-1.238.574-1.741l3.923-5.289V28.808c0-8.59 5.698-15.84 13.49-18.092v-.09c0-2.855 2.292-5.18 5.132-5.18s5.132 2.325 5.132 5.18v.09c7.793 2.253 13.49 9.502 13.49 18.092a.635.635 0 0 1-1.268 0c0-8.18-5.535-15.048-13.016-16.994l-.475-.123v-1.066c0-2.166-1.736-3.91-3.863-3.91m-6.079 43.198c.314 3.123 2.922 5.551 6.08 5.551s5.765-2.428 6.079-5.551z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }