import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "7",
  height: "11",
  fill: "none",
  viewBox: "0 0 7 11"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#27AE60",
      d: "M6 7.429 3.5 10m0 0L1 7.429M3.5 10V0"
    }, null, -1)
  ])))
}
export default { render: render }