import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#000",
      d: "M20.73 22.783V10.664h1.397c.185 0 .36-.08.48-.219a.6.6 0 0 0-.08-.857L12.4 1.358a.64.64 0 0 0-.8 0L1.473 9.588a.6.6 0 0 0-.224.468c0 .336.28.608.624.608H3.27v12.119h5.346v-5.17c0-1.008.838-1.825 1.873-1.825h3.022c1.035 0 1.874.817 1.874 1.825v5.17zM21.978 24h-7.842v-6.387a.617.617 0 0 0-.625-.608H10.49a.617.617 0 0 0-.625.608V24H2.022V11.881h-.149C.84 11.881 0 11.064 0 10.056 0 9.514.247 9 .674 8.653L10.8.423a1.91 1.91 0 0 1 2.4 0l10.126 8.23c.795.646.902 1.797.24 2.572a1.9 1.9 0 0 1-1.44.656h-.148z"
    }, null, -1)
  ])))
}
export default { render: render }