import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "15",
  height: "15",
  fill: "none",
  viewBox: "0 0 15 15"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#4F4F4F",
      "stroke-width": "2",
      d: "m6.5 1 6.364 6.364L6.5 13.728"
    }, null, -1)
  ])))
}
export default { render: render }