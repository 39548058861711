import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#EB5757",
      "fill-rule": "evenodd",
      stroke: "#EB5757",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M21.304 2.591C20.218 1.572 18.745 1 17.209 1s-3.008.572-4.094 1.591L12 3.638 10.884 2.59C8.623.47 4.957.47 2.696 2.59s-2.261 5.56 0 7.681L3.81 11.32 12 19l8.188-7.681 1.116-1.047C22.39 9.254 23 7.872 23 6.432s-.61-2.822-1.696-3.84Z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }